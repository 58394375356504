/**
 * Class Extend
 */
class VideoPlaylist {
  singleVideo(wrapper, options, infos) {
    const myVideo = new Plyr(wrapper, options);
    // console.log("this is from new class", myVideo);
  }

  /**
   *
   * @param {player wrapper} wrapper
   * @param {Plyr options} options
   * @param {object} videos
   * @param {object} infos
   */
  static playlist(wrapperr, optionss = false, videoss = false, infoss = false) {
    const getDetectorData = this.getDetectorData(wrapperr, optionss, videoss, infoss);
    if (!getDetectorData) {
      return false;
    }
    const { wrapper, mediaElement, options, videos, infos } = getDetectorData;

    // infos = { ...infos };
    let myThis = this;
    let $ = jQuery;
    infos.sourceClass = "playlist" + infos.id;
    const wrapperWidth = $(wrapper).width();
    //let sourceClass = infos.sourceClass;
    infos.listItems = $(wrapper).find(".list_items");
    infos.lastIndex = videos.length - 1;
    infos.firstVideoSource = $(wrapper).find(".item0").find("a").attr("source");
    infos.firstVideoThumb = $(wrapper).find(".item0").find("a").attr("poster");
    infos.firstVideoProvider = $(wrapper).find(".item0").find("a").attr("provider");
    let currentIndex = $(wrapper).attr("videoindex");
    let windowWidth = window.innerWidth;

    //set controls base on device
    options.controls = myThis.getControls(options.controls, windowWidth);
    options.seekTime = parseInt(options.seekTime);

    // Create Player Object
    const playlistVideo = new Plyr(mediaElement, options);

    //orientation
    if (window?.innerWidth < 992) {
      playlistVideo.on("enterfullscreen", (event) => {
        screen?.orientation?.lock("landscape");
      });

      playlistVideo.on("exitfullscreen", (event) => {
        screen?.orientation?.lock("portrait");
      });
    }

    //set hegith of the player
    if (infos?.viewType === "rightside") {
      playlistVideo.on("ready", () => {
        setTimeout(() => {
          const videoHeight = wrapper.querySelector(".plyr__video-wrapper")?.clientHeight;
          console.log(wrapper.querySelector(".plyr__video-wrapper"));
          if (videoHeight) {
            console.log("eight", videoHeight);
            $(wrapper)
              .find(".video_wrapper")
              .css("height", videoHeight + "px");
            $(wrapper)
              .find(".rightside")
              .css("height", videoHeight + "px");
          }
        }, 500);
      });
    } else {
      // this.setHeight(wrapper, playlistVideo);
    }

    //remove data atributes
    if (wrapper.getAttribute("data-videos")) {
      wrapper.removeAttribute("data-videos");
    }
    if (wrapper.getAttribute("data-options")) {
      wrapper.removeAttribute("data-options");
    }
    if (wrapper.getAttribute("data-infos")) {
      wrapper.removeAttribute("data-infos");
    }

    //enable carousel if it is selected
    if (infos?.slideVideos) {
      myThis.enableCarousel(infos?.listItems, infos?.carouselItems, windowWidth);
    }

    //initialize player status
    let allIndex = Object.keys(videos);
    allIndex.map((index) => {
      myThis.updateStatus(playlistVideo, index, currentIndex, $(wrapper).find(".item" + index));
    });

    // equal height
    this.equalHeight(wrapper);

    //update status/video on click
    setTimeout(() => {
      $(wrapper)
        .find("li")
        .on("click", function (event) {
          event.preventDefault();
          myThis.onClick(wrapper, playlistVideo, videos, $(this).find("a"), allIndex, infos);
          //scroll top when click on a video
          myThis.scrollTopOnPlayVideo(wrapper, playlistVideo);
        });
    }, 1000);

    //update status/video on current video ended
    playlistVideo.on("ended", function () {
      myThis.onEnded(wrapper, playlistVideo, videos, allIndex, infos);
    });
  }

  /**
   *
   * @param {int} index
   * @param {int} currentIndex
   * @param {Dom wrapper} itemThis
   * @param {player object} playlistVideo
   * @param {boolean} play
   */
  static updateStatus(player, index, currentIndex, itemThis) {
    let $ = jQuery;
    if (index == currentIndex) {
      // $(itemThis).find(".prev_video").remove();
      // $(itemThis).find(".next_video").remove();
      $(itemThis).addClass("active");
      // if ($(itemThis).find(".now_playing").length == 0) {
      //   $(itemThis).find("div.video_title").prepend('<span class="now_playing" style="display: block">Current Video</span>');
      // }
      $(itemThis).find("a svg").replaceWith('<svg aria-hidden="true" focusable="false"><use xlink:href="#plyr-pause"></use></svg>');
    } else {
      $(itemThis).removeClass("active");
      // $(itemThis).find(".now_playing").remove();
      // $(itemThis).find(".next_video").remove();
      // $(itemThis).find(".prev_video").remove();
      // if (index == parseInt(parseInt(currentIndex) - 1)) {
      //   $(itemThis).find("div.video_title").prepend('<span class="prev_video" style="display: block">Prev</span>');
      // } else if (index == parseInt(parseInt(currentIndex) + 1)) {
      //   $(itemThis).find("div.video_title").prepend('<span class="next_video" style="display: block">Up Next</span>');
      // }
      $(itemThis).find("a svg").replaceWith('<svg aria-hidden="true" focusable="false"><use xlink:href="#plyr-play"></use></svg>');
    }
  }

  /**
   * Play video on click thumb
   * @param {player object} playlistVideo
   * @param {object} videos
   * @param {current item wrapper} item
   * @param {int array} allIndex
   * @param {object} infos
   */
  static onClick(wrapper, playlistVideo, videos, item, allIndex, infos) {
    let $ = jQuery;
    let source = $(item).attr("source");
    let poster = $(item).attr("poster");
    let newIndex = $(item).attr("videoindex");
    let quality = videos[newIndex].h5vp_quality_playerio;
    let subtitle = videos[newIndex].h5vp_subtitle_playerio;
    let provider = $(item).attr("provider");
    let videoHeight = $(item).find("video")[0].videoHeight;
    let currentSource = playlistVideo.source;
    let ytCurrentId;
    let ytNewId;

    //get youtube currentId & newId
    if (provider == "youtube") {
      ytCurrentId = currentSource.split("v=");
      ytNewId = source;
      if (ytCurrentId.length > 1) {
        ytCurrentId = ytCurrentId[1].split("&")[0];
      }
      if (source !== 11) {
        ytNewId = source.split("v=")[1]?.split("&")?.[0] || source;
      }
    }

    // Prevent to set source if already the video is running
    if (ytCurrentId != ytNewId || (playlistVideo.source != source && provider != "youtube")) {
      this.setSource(playlistVideo, provider, source, this.getQuality(quality), poster, this.getSubtitle(subtitle), true, videoHeight);
      $(wrapper).attr("videoindex", newIndex);
    }

    //update video status playing or not
    let currentIndex = $(wrapper).attr("videoindex");
    allIndex.map((index) => {
      this.updateStatus(playlistVideo, index, currentIndex, $(wrapper).find(".item" + index));
    });

    //controls play/pause click on video thumb
    this.playOnThumb(playlistVideo, provider);
  }

  /**
   * Change video and play after ended a video
   * @param {player object} playlistVideo
   * @param {object} videos
   * @param {int arrray} allIndex
   * @param {object} infos
   */
  static onEnded(wrapper, playlistVideo, videos, allIndex, infos) {
    let $ = jQuery;
    let currentIndex = $(wrapper).attr("videoindex");
    let quality = videos[0].h5vp_quality_playerio;
    let subtitle = videos[0].h5vp_subtitle_playerio;
    let videoHeight = $(".item0").find("video")[0].videoHeight;

    //playlist loop
    if (currentIndex == infos.lastIndex && infos.loop == "yes") {
      this.setSource(
        playlistVideo,
        infos.firstVideoProvider,
        infos.firstVideoSource,
        this.getQuality(quality),
        infos.firstVideoThumb,
        this.getSubtitle(subtitle),
        true,
        videoHeight
      );
      $(wrapper).attr("videoindex", 0);
      this.playOnThumb(playlistVideo, infos.firstVideoProvider);
    }

    //autoplay next video
    if (infos.next == "yes") {
      allIndex.map((index) => {
        if (index == parseInt(parseInt(currentIndex) + 1)) {
          let item = $(wrapper).find(".item" + index);
          let source = $(item).find("a").attr("source");
          let poster = $(item).find("a").attr("poster");
          let provider = $(item).find("a").attr("provider");
          let newIndex = $(item).find("a").attr("videoindex");
          let videoHeight = $(item).find("video")[0].videoHeight;
          let quality = videos[newIndex].h5vp_quality_playerio;
          let subtitle = videos[newIndex].h5vp_subtitle_playerio;
          this.setSource(playlistVideo, provider, source, this.getQuality(quality), poster, this.getSubtitle(subtitle), true, videoHeight);
          $(wrapper).attr("videoindex", newIndex);
          //$("."+infos.sourceClass).find('.owl-nav button.owl-next').trigger('click');
          this.playOnThumb(playlistVideo, provider);
        }
      });
    }

    currentIndex = $(wrapper).attr("videoindex");
    allIndex.map((index) => {
      this.updateStatus(playlistVideo, index, currentIndex, $(wrapper).find(".item" + index));
    });
  }

  /**
   *
   * @param {array with object} quality
   */
  static getQuality(quality) {
    let videoQuality = [];
    if (typeof quality != "undefined") {
      let length = quality.length - 1;
      for (let i = 0; i <= length; i++) {
        videoQuality[i] = {
          src: quality[i].video_file,
          size: quality[i].size,
        };
      }
    }
    return videoQuality;
  }

  /**
   *
   * @param {string} subtitle
   */
  static getSubtitle(subtitle) {
    let videoSubtitle = [];
    if (typeof subtitle != "undefined") {
      let length = subtitle.length - 1;
      for (let i = 0; i <= length; i++) {
        videoSubtitle[i] = {
          kind: "captions",
          label: subtitle[i].label,
          src: subtitle[i].caption_file,
          default: true,
        };
      }
    }
    return videoSubtitle;
  }

  /**
   *
   * @param {Player Object} playlistVideo
   * @param {string} provider
   * @param {string} source
   * @param {array->object} quality
   * @param {string} poster
   * @param {string} subtitle
   * @param {boolean} play
   * @param {int} height
   */
  static setSource(playlistVideo, provider, source, quality, poster, subtitle, play = true, height = 720) {
    if (provider == "library") {
      playlistVideo.source = {
        type: "video",
        title: "Example title",
        sources: [
          {
            src: source,
            type: "video/mp4",
            size: height,
          },
          ...quality,
        ],
        poster: poster,
        tracks: subtitle,
      };
    } else {
      playlistVideo.source = {
        type: "video",
        sources: [
          {
            src: source,
            provider: provider,
          },
        ],
      };
    }
  }

  /**
   *
   * @param {player object} player
   * @param {string} provider
   */
  static playOnThumb(player, provider = "library") {
    if (player.playing == true && provider == "library") {
      player.pause();
    } else if ((player.playing == true && provider == "youtube") || provider == "vimeo") {
      setTimeout(() => {
        player.play();
      }, 2500);
    } else {
      player.play();
    }

    // if(player.playing == true){
    //     player.pause();
    // }else {
    //     player.play();
    // }
  }

  /**
   *
   * @param {string} listItems
   * @param {int} items
   * @param {int} windowWidth
   */
  static enableCarousel(listItems, items = 3, windowWidth) {
    let $ = jQuery;
    $(listItems).removeClass("h5vpNotSlide").addClass("owl-carousel");
    if (windowWidth < 768) {
      $(listItems).removeClass("owl-carousel").removeClass("listwithposter").addClass("simplelist");
      $(listItems).find("img.video_thumb").remove();
    }
    if ($.fn.owlCarousel) {
      $(".list_items.listwithposter").owlCarousel({
        loop: false,
        rewind: true,
        margin: 10,
        nav: true,
        items,
        dots: false,
        // responsive: {
        //   0: { items: 3 },
        //   800: { items: items },
        // },
      });
    }
  }

  /**
   *
   * @param {object} controlss
   * @param {int} windowWidth
   */
  static getControls(controlss, windowWidth) {
    let controls = [];
    for (let [key, value] of Object.entries(controlss)) {
      if ((value == "show" || value == "mobile") && windowWidth > 576) {
        controls.push(key);
      } else if (value != "mobile" && value != "hide" && windowWidth < 576) {
        controls.push(key);
      }
    }
    return controls;
    // options.controls = controls;
  }

  static getDetectorData(wrapper, options, videos, infos) {
    let mediaElement = null;

    // get the exact wrapper
    if (wrapper === null) {
      return false;
    }
    if (typeof wrapper[0] !== "undefined") {
      wrapper.map((index, item) => {
        this.playlist(item, options, videos, infos);
      });
      return false;
    }
    if (typeof wrapper.length !== "undefined" && wrapper.length === 0) {
      return false;
    }
    if (wrapper.querySelector(".h5vp_video_playlist") !== null) {
      wrapper = wrapper.querySelector(".h5vp_video_playlist");
    }

    //get data from attribute if it not pass in function
    if (!options) {
      options = JSON.parse(jQuery(wrapper).attr("data-options"));
    }
    if (!videos) {
      videos = JSON.parse(jQuery(wrapper).attr("data-videos"));
    }
    if (!infos) {
      infos = JSON.parse(jQuery(wrapper).attr("data-infos"));
    }

    if (infos?.provider === "library") {
      mediaElement = wrapper.querySelector("video");
    } else {
      mediaElement = wrapper.querySelector("div#notLibrary");
    }

    return {
      wrapper,
      options,
      mediaElement,
      infos,
      videos,
    };
  }

  static setHeight(wrapper, media) {
    const wrapperWidth = jQuery(wrapper).width();
    media.on("loadeddata", function () {
      const str = media.ratio;
      if (!str) return false;
      const [r1, r2] = str.split(":");
      const height = (wrapperWidth / r1) * r2;
      jQuery(wrapper)
        .find(".plyr")
        .css("height", height + "px");
    });
  }

  static equalHeight(wrapper) {
    let height = 0;
    const items = jQuery(wrapper).find(".listwithposter li");
    items.map((index, item) => {
      const newHeight = jQuery(item).find(".video_title").height();
      if (height < newHeight) {
        height = newHeight;
      }
    });
    items.map((index, item) => {
      jQuery(item).find(".video_title h3").css("height", height);
    });
  }

  static scrollTopOnPlayVideo(wrapper, media) {
    const videoWrapper = wrapper.querySelector(".video_wrapper");
    const items = wrapper.querySelector(".list_items");
    if (videoWrapper && items) {
      const rect = videoWrapper.getBoundingClientRect();
      if (videoWrapper.offsetTop < items.offsetTop && window.innerWidth < 768) {
        window.scrollTo({
          top: rect.top + document.documentElement.scrollTop - 100,
          lef: 0,
          behavior: "smooth",
        });
      }
    }
  }
}

export default VideoPlaylist;
